import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageProps } from 'gatsby';
import SEO from '../components/SEO';
import InternalBanner from '../components/InternalBanner';
import VignetteImg from '../images/CU-bann.jpg';
import SectionHeading from '../components/SectionHeading';
import InternalBody from '../components/InternalBody';
import {
  InternalBannerWrapper,
  InternalParagrapheSection,
  InternalTitle,
} from '../components/Layout/styles';

import { messages as globalMessages } from '../components/messages';
import InternalLayout from '../components/Layout/InternalLayout';

function LegalNotice({ location }: PageProps) {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        keywords={[
          'paiement vignette maroc',
          'ma vignette',
          'payer en ligne vignette maroc',
          'paiement vignette maroc en ligne',
          'payer vignette en ligne',
          'vignette en ligne',
          'ma vignette maroc',
          'paiement en ligne maroc',
          'paiement internet maroc',
          'impot voiture maroc',
          'taxe voiture maroc',
          'payer taxe voiture maroc',
          'payer dariba maroc',
          'payer dariba en ligne',
          'payer dariba',
          'vignette 2022',
          'payer vignette 2022',
          'payer vignette 2022 maroc',
        ]}
      />
      <InternalLayout path={location.pathname.replace(/\//g, '')}>
        <InternalBannerWrapper>
          <InternalBanner BannerBg={VignetteImg} BgPosition="50% 100%">
            <SectionHeading
              title={t(globalMessages.navigationBar.legalNoticeLinkTitle())}
              subTitle="&nbsp;"
              align="center"
              withShadow
            />
          </InternalBanner>
          <InternalBody>
            <InternalParagrapheSection>
              <p>
                Société Générale Marocaine de Banques est une Société Anonyme à
                Directoire et Conseil de Surveillance au capital de 2 050 000
                000 Dirhams, immatriculée au registre du commerce de Casablanca
                sous le numéro 28987 – Id Fis : 01084160 - Patente : 36363350
                Son Siège Social est situé au 55, Boulevard Abdelmoumen, 20100
                Casablanca.
              </p>
            </InternalParagrapheSection>
            <InternalTitle>PROPRIÉTÉS ET MARQUE</InternalTitle>
            <InternalParagrapheSection>
              <p>
                L'ensemble de ce Site relève de la législation marocaine sur le
                droit d'auteur, le droit des marques et, de façon générale, sur
                la propriété intellectuelle, aussi bien en ce qui concerne sa
                forme (choix, plan, disposition des matières, moyens d'accès aux
                données, organisation des données...), qu'en ce qui concerne
                chacun des éléments de son contenu (textes, images...). Ces
                contenus, figurant sur les pages de ce Site, sont la propriété
                exclusive du groupe Société Générale et de ses contributeurs.
                Par exception, certains contenus (textes, images) sont la
                propriété intellectuelle de leurs auteurs respectifs. Toute
                reproduction, représentation, diffusion ou rediffusion, en tout
                ou partie, du contenu de ce Site sur quelque support ou par tout
                procédé que ce soit, de même que toute vente, revente,
                retransmission ou mise à disposition de tiers de quelque manière
                que ce soit sont interdites. Le non-respect de cette
                interdiction constitue une contrefaçon susceptible d'engager la
                responsabilité civile et pénale du contrefacteur.
              </p>
            </InternalParagrapheSection>
          </InternalBody>
        </InternalBannerWrapper>
      </InternalLayout>
    </>
  );
}

export default memo(LegalNotice);
